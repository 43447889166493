<template>
  <b-modal
    id="remove-blocked-number-confirmation-modal"
    name="VirtualPhoneRemoveBlockedNumberConfirmationModal"
    title="Remove Blocked Number Confirmation"
    centered
    hide-header
    hide-footer
  >
    <b-row>
      <b-col>Unblock {{ formattedNumber }}?</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          @click="removeBlockedNumber()"
          aria-label="Remove Blocked Number Button"
        >
          Confirm
        </b-button>
      </b-col>
      <b-col>
        <b-button
          variant="danger"
          @click="closeModal()"
          aria-label="Close Blocked Number Confirmation Modal Button"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: "VirtualPhoneRemoveBlockedNumberConfirmationModal",
    props: {
      blockedNumberId: String,
      formattedNumber: String,
    },
    methods: {
      ...mapActions("virtualPhones", ["unblockNumber"]),
      closeModal() {
        this.$bvModal.hide("remove-blocked-number-confirmation-modal")
      },
      async removeBlockedNumber() {
        await this.unblockNumber(this.blockedNumberId)
        this.closeModal()
      },
    },
  }
</script>
