<template>
  <div>
    <b-list-group-item class="p-0 mt-2 rounded-3" variant="dark" :active="active" @click="toggleActive">
      <b-row class="m-auto">
        <b-col cols="2">
          <b-icon v-if="active" icon="arrow-return-left" variant="dark" @click="toggleRemoveBlockedNumberConfirmationModal" />
          <b-icon v-else icon="slash-circle" variant="dark" />
        </b-col>
        <b-col class="virtual-phone-blocked-number-text">
          {{ formatNumber(number.blocked_number) }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <virtual-phone-remove-blocked-number-confirmation-modal
      :blocked-number-id="number.id"
      :formatted-number="formatNumber(number.blocked_number)"
    />
  </div>
</template>
<script>
import VirtualPhoneRemoveBlockedNumberConfirmationModal
  from '@/components/VirtualPhones/VirtualPhoneRemoveBlockedNumberConfirmationModal.vue'
export default {
  name: "VirtualPhoneBlockedNumberMobileView",
  components: { VirtualPhoneRemoveBlockedNumberConfirmationModal },
  props: {
    number: Object,
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    formatNumber(number) {
      const countryCode = number.slice(0, 2)
      const areaCode = number.slice(2, 5)
      const firstThreeDigits = number.slice(5, 8)
      const lastFourDigits = number.slice(8)

      return `${countryCode} (${areaCode}) ${firstThreeDigits}-${lastFourDigits}`
    },
    toggleActive() {
      this.active = !this.active
    },
    toggleRemoveBlockedNumberConfirmationModal() {
      this.$bvModal.show("remove-blocked-number-confirmation-modal")
    },
  },
}
</script>

<style scoped lang="scss">
  .virtual-phone-blocked-number-text {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.021rem;
    align-self: center;
  }
</style>
