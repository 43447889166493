var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-list-group-item",
        {
          staticClass: "p-0 mt-2 rounded-3",
          attrs: { variant: "dark", active: _vm.active },
          on: { click: _vm.toggleActive },
        },
        [
          _c(
            "b-row",
            { staticClass: "m-auto" },
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _vm.active
                    ? _c("b-icon", {
                        attrs: { icon: "arrow-return-left", variant: "dark" },
                        on: {
                          click: _vm.toggleRemoveBlockedNumberConfirmationModal,
                        },
                      })
                    : _c("b-icon", {
                        attrs: { icon: "slash-circle", variant: "dark" },
                      }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "virtual-phone-blocked-number-text" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.formatNumber(_vm.number.blocked_number)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("virtual-phone-remove-blocked-number-confirmation-modal", {
        attrs: {
          "blocked-number-id": _vm.number.id,
          "formatted-number": _vm.formatNumber(_vm.number.blocked_number),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }