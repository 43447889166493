<template>
  <b-modal
    id="blocked-numbers-modal"
    size="lg"
    name="VirtualPhoneBlockedNumbersModal"
    title="Blocked Numbers"
    centered
    header-class="blocked-numbers-header"
    footer-class="blocked-numbers-footer"
    body-class="blocked-numbers-body"
  >
    <b-row>
      <b-col>
        <label class="phone-settings-label">Select the numbers you would like to unblock</label>
      </b-col>
      <b-col class="d-flex justify-content-end select-all">
        <label for="select-all-checkbox" class="mt-auto mb-auto mr-2 select-all-checkbox-label">Select All</label>
        <b-form-checkbox v-model="allNumbersSelected" name="select-all-checkbox" size="lg" @change="updateSelectedNumbers" />
      </b-col>
    </b-row>
    <b-list-group>
      <b-form-checkbox-group
        id="blocked-numbers-checkbox-group"
        v-model="selected"
      >
        <div v-for="blockedNumber in blockedNumbers" :key="blockedNumber">
          <b-list-group-item class="p-0 mt-2 rounded-3 blocked-number-list-item" variant="dark" :name="blockedNumber">
            <b-row class="m-auto pt-1 pb-1">
              <b-col>
                {{ formatNumber(blockedNumber.blocked_number) }}
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-form-checkbox :value="blockedNumber.id" size="lg" />
              </b-col>
            </b-row>
          </b-list-group-item>
        </div>
      </b-form-checkbox-group>
    </b-list-group>
    <template #modal-footer>
      <b-col>
        <b-button
          class="blocked-numbers-modal-button modal-button-light"
          aria-label="Cancel button"
          @click="closeModal"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col cols="9" class="d-flex justify-content-end">
        <b-button
          class="blocked-numbers-modal-button deselect-button"
          aria-label="Deselect All Button"
          @click="deselectAllNumbers"
        >
          Deselect All
        </b-button>
        <b-button
          class="blocked-numbers-modal-button modal-button-dark ml-2"
          aria-label="Unblock Selected Numbers button"
          @click="unblockSelectedNumbers"
        >
          Unblock Selected Numbers
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: "VirtualPhoneBlockedNumbersModal",
  props: {
    blockedNumbers: Array,
  },
  data() {
    return {
      allNumbersSelected: false,
      selected: [],
    }
  },
  methods: {
    ...mapActions("virtualPhones", ["unblockNumber", "getBlockedNumbers"]),
    closeModal() {
      this.$bvModal.hide("blocked-numbers-modal")
    },
    async removeBlockedNumber() {
      await this.unblockNumber(this.blockedNumberId)
    },
    updateSelectedNumbers() {
      if (this.allNumbersSelected) {
        this.selected = this.blockedNumbers.map(
          (blockedNumber) => blockedNumber.id
        )
      } else {
        this.selected = []
      }
    },
    deselectAllNumbers() {
      this.selected = []
      this.allNumbersSelected = false
    },
    async unblockSelectedNumbers() {
      if (!this.selected.length) {
        return
      }

      try {
        const unblockPromises = this.selected.map((blockedNumberId) =>
          this.unblockNumber(blockedNumberId)
        )

        await Promise.all(unblockPromises)

        this.selected = []
        this.allNumbersSelected = false
        await this.getBlockedNumbers()

        this.closeModal()

        this.$bvToast.toast("Selected numbers have been unblocked successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        })
      } catch (error) {
        console.error("Error unblocking numbers:", error)
        this.$bvToast.toast("An error occurred while unblocking numbers.", {
          title: "Error",
          variant: "danger",
          solid: true,
        })
      }
    },
    formatNumber(number) {
      const countryCode = number.slice(0, 2)
      const areaCode = number.slice(2, 5)
      const firstThreeDigits = number.slice(5, 8)
      const lastFourDigits = number.slice(8)

      return `${countryCode} (${areaCode}) ${firstThreeDigits}-${lastFourDigits}`
    },
  },
}
</script>

<style scoped lang="scss">
  :deep .blocked-numbers-header {
    background-color: #FFFFFF !important;
    color: #4E4E52;
    border-bottom: 0.063rem solid #E1E1E5;

    .close {
      color: #4E4E52;
    }
  }

  :deep .blocked-numbers-footer {
    background-color: #FFFFFF;
    justify-content: flex-start;
  }

  :deep .blocked-numbers-body {
    background-color: #FFFFFF;
    max-height: 50vh;
    overflow: scroll;
  }

  .blocked-numbers-modal-button {
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 1rem;
  }

  .modal-button-light {
    background-color: #FFFFFF;
    color: #4E4E52;
    border: 0.063rem solid #CECED2;
  }

  .modal-button-dark {
    background-color: #2E3798;
    color: #FFFFFF;
    border: 0.063rem solid #CECED2;
  }

  .deselect-button {
    background-color: #FFFFFF;
    color: #2E3798;
    border: 0.063rem solid #2E3798;
  }

  .phone-settings-label {
    color: #4E4E52;
  }

  .blocked-number-list-item {
    background-color: #F7F7FB;
    color: #616165;
  }

  .select-all {
    padding-right: 2.6rem;
  }

  .select-all-checkbox-label {
    color: #4E4E52;
    font-weight: 400;
  }
</style>
