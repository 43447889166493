var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "blocked-numbers-modal",
        size: "lg",
        name: "VirtualPhoneBlockedNumbersModal",
        title: "Blocked Numbers",
        centered: "",
        "header-class": "blocked-numbers-header",
        "footer-class": "blocked-numbers-footer",
        "body-class": "blocked-numbers-body",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "blocked-numbers-modal-button modal-button-light",
                      attrs: { "aria-label": "Cancel button" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end",
                  attrs: { cols: "9" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "blocked-numbers-modal-button deselect-button",
                      attrs: { "aria-label": "Deselect All Button" },
                      on: { click: _vm.deselectAllNumbers },
                    },
                    [_vm._v("\n        Deselect All\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "blocked-numbers-modal-button modal-button-dark ml-2",
                      attrs: {
                        "aria-label": "Unblock Selected Numbers button",
                      },
                      on: { click: _vm.unblockSelectedNumbers },
                    },
                    [_vm._v("\n        Unblock Selected Numbers\n      ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("label", { staticClass: "phone-settings-label" }, [
              _vm._v("Select the numbers you would like to unblock"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-end select-all" },
            [
              _c(
                "label",
                {
                  staticClass: "mt-auto mb-auto mr-2 select-all-checkbox-label",
                  attrs: { for: "select-all-checkbox" },
                },
                [_vm._v("Select All")]
              ),
              _c("b-form-checkbox", {
                attrs: { name: "select-all-checkbox", size: "lg" },
                on: { change: _vm.updateSelectedNumbers },
                model: {
                  value: _vm.allNumbersSelected,
                  callback: function ($$v) {
                    _vm.allNumbersSelected = $$v
                  },
                  expression: "allNumbersSelected",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-list-group",
        [
          _c(
            "b-form-checkbox-group",
            {
              attrs: { id: "blocked-numbers-checkbox-group" },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            },
            _vm._l(_vm.blockedNumbers, function (blockedNumber) {
              return _c(
                "div",
                { key: blockedNumber },
                [
                  _c(
                    "b-list-group-item",
                    {
                      staticClass:
                        "p-0 mt-2 rounded-3 blocked-number-list-item",
                      attrs: { variant: "dark", name: blockedNumber },
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "m-auto pt-1 pb-1" },
                        [
                          _c("b-col", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.formatNumber(blockedNumber.blocked_number)
                                ) +
                                "\n            "
                            ),
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              _c("b-form-checkbox", {
                                attrs: { value: blockedNumber.id, size: "lg" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }