var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "remove-blocked-number-confirmation-modal",
        name: "VirtualPhoneRemoveBlockedNumberConfirmationModal",
        title: "Remove Blocked Number Confirmation",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
      },
    },
    [
      _c(
        "b-row",
        [_c("b-col", [_vm._v("Unblock " + _vm._s(_vm.formattedNumber) + "?")])],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    "aria-label": "Remove Blocked Number Button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeBlockedNumber()
                    },
                  },
                },
                [_vm._v("\n        Confirm\n      ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "danger",
                    "aria-label":
                      "Close Blocked Number Confirmation Modal Button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }